<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-card class="card-shadow border-radius-xl">
        <div class="ma-5">
          <v-form ref="frmFilter" class="pt-8">
            <v-row class="mt-8" dense>
              <v-col cols="12" sm="4" md="3">
                <label class="text-md font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span
                  >Vendor</label
                >
                <v-autocomplete
                  v-model="vendor"
                  :items="vendors"
                  item-text="name"
                  item-value="id"
                  return-object
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  placeholder="Select a Vendor"
                  :rules="[(v) => !!v || 'Vendor is Required']"
                  outlined
                  single-line
                  height="46"
                  @change="getSeason"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="3">
                <label class="text-md font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span
                  >Season</label
                >
                <v-autocomplete
                  return-object
                  v-model="season"
                  :items="seasons"
                  item-text="name"
                  item-value="id"
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  placeholder="Select a Season"
                  :rules="[(v) => !!v || 'Season is Required']"
                  outlined
                  single-line
                  height="46"
                  @change="getOffers"
                >
                </v-autocomplete> </v-col
              ><v-col cols="12" sm="4" md="3">
                <label class="text-md font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span>Offer
                  No</label
                >
                <v-autocomplete
                  return-object
                  v-model="offer"
                  :items="offers"
                  item-text="offer_no"
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  @change="getList"
                  placeholder="Select a Offer"
                  :rules="[(v) => !!v || 'Offer is Required']"
                  outlined
                  single-line
                  height="46"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4" md="3">
                <label class="text-md font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span>List
                  Name</label
                >
                <v-autocomplete
                  return-object
                  v-model="list"
                  :items="lists"
                  item-text="list_name"
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  placeholder="Select a List"
                  :rules="[(v) => !!v || 'List is Required']"
                  outlined
                  single-line
                  height="46"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end mx-5">
                <v-btn
                  @click="clear"
                  elevation="0"
                  :ripple="false"
                  height="46"
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-secondary
                    bg-light
                    py-3
                    px-6
                    mx-2
                  "
                  >Clear</v-btn
                >

                <v-btn
                  @click="searchData"
                  elevation="0"
                  :ripple="false"
                  height="46"
                  dark
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-primary
                    bg-success
                    py-3
                    px-6
                  "
                  >Search</v-btn
                ></v-col
              >
            </v-row>
          </v-form>
        </div>
      </v-card>

      <v-card class="card-shadow border-radius-xl mt-6" v-show="showTable">
        <v-card-text class="card-padding" id="scroll_1">
          <Tasting
            :tableData="myAllocation"
            :validationData="validationData"
            v-if="myAllocation.length > 0"
            @submit="save"
          ></Tasting>
          <!-- <v-form ref="frmTable">
            <v-data-table
              v-model="tastingData"
              :headers="headers"
              :items="myAllocation"
              item-key="id"
              mobile-breakpoint="0"
              fixed-header
              class="table"
              :footer-props="{
                'items-per-page-options': [100, 200, 500, -1],
              }"
              disable-pagination
              hide-default-footer
              @keyup="changeFocusInput"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>My Allocation</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    :ripple="false"
                    height="46"
                    dark
                    @click="submit"
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                      mb-5
                      mt-5
                    "
                    >Submit
                  </v-btn>
                </v-toolbar>
              </template>

              <template v-slot:[`item.item_name`]="{ item }">
                <v-text-field
                  disabled
                  v-model="item.item_name"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-2
                    mb-4
                    centered-input
                  "
                  dense
                  flat
                  filled
                  solo
                  height="46"
                  persistent-hint
                ></v-text-field>
              </template>
              <template v-slot:[`item.leaf_appearance`]="{ item, index }">
                <v-select
                  v-model.number="item.leaf_appearance"
                  :items="leaf_appearance"
                  item-text="value"
                  item-value="value"
                  @change="getItemName(item)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  outlined
                  single-line
                  height="46"
                  :ref="`input-${index * 7 + 1}`"
                  @keyup="changeFocusInput"
                  style="width: 70px"
                >
                </v-select>
              </template>

              <template v-slot:[`item.leaf_size`]="{ item, index }">
                <v-select
                  v-model.number="item.leaf_size"
                  :items="leaf_size"
                  item-text="value"
                  item-value="value"
                  @change="getItemName(item)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  outlined
                  single-line
                  height="46"
                  :ref="`input-${index * 7 + 2}`"
                  @keyup="changeFocusInput"
                  style="width: 70px"
                >
                </v-select>
              </template>

              <template v-slot:[`item.color`]="{ item, index }">
                <v-select
                  v-model.number="item.color"
                  :items="color"
                  item-text="value"
                  item-value="value"
                  @change="getItemName(item)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  outlined
                  single-line
                  height="46"
                  :ref="`input-${index * 7 + 3}`"
                  @keyup="changeFocusInput"
                  style="width: 70px"
                >
                </v-select>
              </template>
              <template v-slot:[`item.body`]="{ item, index }">
                <v-select
                  v-model.number="item.body"
                  :items="body"
                  item-text="value"
                  item-value="value"
                  @change="getItemName(item)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  outlined
                  single-line
                  height="46"
                  :ref="`input-${index * 7 + 4}`"
                  @keyup="changeFocusInput"
                  style="width: 70px"
                >
                </v-select>
              </template>
              <template v-slot:[`item.taste`]="{ item, index }">
                <v-select
                  v-model.number="item.taste"
                  :items="taste"
                  item-text="value"
                  item-value="value"
                  @change="getItemName(item)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  outlined
                  single-line
                  height="46"
                  :ref="`input-${index * 7 + 5}`"
                  @keyup="changeFocusInput"
                  style="width: 70px"
                >
                </v-select>
              </template>
              <template v-slot:[`item.special_marking`]="{ item }">
                <v-text-field
                  tabindex="-1"
                  v-model="item.special_marking"
                  hide-details="auto"
                  @input="getItemName(item)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-2
                    mb-4
                    centered-input
                  "
                  dense
                  flat
                  filled
                  solo
                  height="46"
                  :value="item.special_marking"
                  persistent-hint
                  @keyup="changeFocusInput"
                  style="width: 100px"
                ></v-text-field>
              </template>
              <template v-slot:[`item.comment`]="{ item, index }">
                <v-text-field
                  v-model="item.comment"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-2
                    mb-4
                    centered-input
                  "
                  dense
                  flat
                  filled
                  solo
                  height="46"
                  :value="item.comment"
                  persistent-hint
                  :ref="`input-${index * 7 + 6}`"
                  @keyup="changeFocusInput"
                  style="width: 70px"
                ></v-text-field>
              </template>
              <template v-slot:[`item.price_valuation`]="{ item, index }">
                <v-text-field
                  v-model="item.price_valuation"
                  v-on="(item.price_valuation = item.price_valuation || 0)"
                  :rules="[(v) => v >= 0 || 'Min should be above 0']"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-2
                    mb-4
                    centered-input
                  "
                  dense
                  flat
                  filled
                  solo
                  height="46"
                  :value="item.price_valuation || 0"
                  :ref="`input-${index * 7 + 7}`"
                  @keyup="changeFocusInput"
                  style="width: 70px"
                ></v-text-field>
              </template>
            </v-data-table> </v-form
        > -->
        </v-card-text>

        <!-- <v-card-actions>
          <v-row>
            <v-col offset-md="5"
              ><v-btn
                elevation="0"
                :ripple="false"
                height="46"
                dark
                @click="submit"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-primary
                  bg-success
                  py-3
                  px-6
                  mb-5
                "
                >Submit
              </v-btn></v-col
            >
          </v-row>
        </v-card-actions> -->
      </v-card>

      <!-- Save Dialogue -->
      <v-dialog v-model="dialogue" max-width="500px" persistent>
        <v-card class="card-shadow card-padding border-radius-xl">
          <v-card-title class="pt-0 text-h5 text-typo justify-center"
            >Do you want to Save?</v-card-title
          >
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="close"
              elevation="0"
              :ripple="false"
              height="46"
              class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
              >No</v-btn
            >

            <v-btn
              @click="save"
              elevation="0"
              :ripple="false"
              height="46"
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-success
                py-3
                px-6
              "
              >Yes</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import Tasting from "../../../../components/TastingComponent/Tasting.vue";

import api from "../api";
import blend from "../../../../api/blend-api";
import apiMaster from "../../../master/master-get-api";
import { LABEL_TASTING_FEATURE } from "../../../../global-data/labels";
export default {
  components: {
    Tasting,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      overlay: false,
      //
      vendors: [],
      vendor: null,
      season: null,
      seasons: [],
      offer: null,
      offers: [],
      list: null,
      lists: [],
      myAllocation: [],
      default: 0,
      showTable: false,

      leaf_appearance: [],
      leaf_size: [],
      color: [],
      body: [],
      taste: [],

      dialogue: false,

      headers: [
        {
          text: "Lot No",
          value: "lot_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Item",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Appearance",
          value: "leaf_appearance",
          align: "center",
          width: 80,
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-10",
        },
        {
          text: "Size",
          value: "leaf_size",
          align: "center",
          width: 80,
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-10",
        },

        {
          text: "Color",
          value: "color",
          align: "center",
          width: 80,
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-10",
        },
        {
          text: "Body",
          value: "body",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 80,
          sortable: false,
        },
        {
          text: "Taste",
          value: "taste",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 80,
          sortable: false,
        },

        {
          text: "Comments",
          value: "comment",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Price valuation",
          value: "price_valuation",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: LABEL_TASTING_FEATURE,
          value: "special_marking",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 100,
          sortable: false,
        },
      ],

      tastingData: [
        {
          isDisable: false,
        },
      ],
      validationData: {},
      editedData: [],
      currentItem: 1,
      gridApi: null,
      columnApi: null,
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    currentItem: {
      handler(value, oldValue) {
        if (value !== oldValue) {
          this.focusInput(value);
        }
      },
      immediate: true,
    },
  },
  methods: {
    getIndex(item) {
      return this.myAllocation.indexOf(item);
    },

    getInput(id) {
      const input = this.$refs[`input-${id}`];
      return input;
    },
    changeFocusInput(event) {
      const key_code = {
        left: 37,
        up: 38,
        right: 39,
        down: 40,
      };

      let nextItem = this.currentItem;

      if (event.keyCode == key_code.left) {
        nextItem -= 1;
      } else if (event.keyCode == key_code.right) {
        nextItem += 1;
      }

      if (this.getInput(nextItem)) {
        this.currentItem = nextItem;
      }
    },
    focusInput(id) {
      const input = this.getInput(id);
      if (input) {
        input.focus();
      }
    },

    searchValidation() {
      return this.$refs.frmFilter.validate();
    },
    tableValidtion() {
      return this.$refs.frmTable.validate();
    },
    scrollDown() {
      this.$nextTick(() =>
        document
          .getElementById("scroll_1")
          .scrollIntoView({ behavior: "smooth" })
      );
    },

    async initialize() {
      try {
        this.overlay = true;
        this.tastingData = [];
        this.editedData = [];
        this.myAllocation = [];
        this.tastingData = [];
        this.showTable = false;

        // ## Geting Blend factors
        let result = await blend.getBlendfactor();
        this.body = result.body;
        this.leaf_size = result.leaf_size;
        this.color = result.color;
        this.taste = result.taste;
        this.leaf_appearance = result.leaf_appearance;

        const leaf_appearance = result.leaf_appearance.map((v) => {
          return v.value;
        });

        this.leaf_appearance = Math.max(...leaf_appearance);
        this.validationData.leaf_appearance = this.leaf_appearance;

        const body = result.body.map((v) => {
          return v.value;
        });
        this.body = Math.max(...body);
        this.validationData.body = this.body;

        const leaf_size = result.leaf_size.map((v) => {
          return v.value;
        });
        this.leaf_size = Math.max(...leaf_size);
        this.validationData.leaf_size = this.leaf_size;

        const color = result.color.map((v) => {
          return v.value;
        });
        this.color = Math.max(...color);
        this.validationData.color = this.color;

        const taste = result.taste.map((v) => {
          return v.value;
        });
        this.taste = Math.max(...taste);
        this.validationData.taste = this.taste;

        this.vendors = await api.getVendors();
      } catch (error) {
        this.showErrorAlert(error);
      } finally {
        this.overlay = false;
      }
      this.overlay = false;
    },
    async getSeason() {
      try {
        this.seasons = [];
        this.season = null;
        this.overlay = true;
        this.offers = [];
        this.offer = null;
        this.lists = [];
        this.list = null;
        this.seasons = await apiMaster.getSeason(this.vendor.id);
      } catch (error) {
        this.showErrorAlert(error);
      } finally {
        this.overlay = false;
      }
    },

    // ## API call from BLEND module
    getItemName(item) {
      if (
        item.body > 0 &&
        item.color > 0 &&
        item.leaf_appearance > 0 &&
        item.leaf_size > 0 &&
        item.taste > 0
      ) {
        blend
          .getItemName(
            item.body,
            item.color,
            item.leaf_appearance,
            item.leaf_size,
            item.taste
          )
          .then((res) => {
            item.item_name_1 = res.data.name;
            item.item_name = this.setItemName(item);
          });
      }
    },

    setItemName(item) {
      if (item.special_marking) {
        return `${item.item_name_1}${item.special_marking}`;
      } else {
        return item.item_name_1;
      }
    },

    async getOffers() {
      try {
        this.offers = [];
        this.offer = null;
        this.lists = [];
        this.list = null;
        this.overlay = true;
        this.offers = await api.getOffers(this.vendor.id, this.season.id);
      } catch (error) {
        this.showErrorAlert(error);
      } finally {
        this.overlay = false;
      }
    },

    async getList() {
      try {
        this.lists = [];
        this.list = null;
        this.overlay = true;
        this.lists = await api.getList(this.offer.id);
      } catch (error) {
        this.showErrorAlert(error);
      } finally {
        this.overlay = false;
      }
    },
    async searchData() {
      this.myAllocation = [];
      this.showTable = false;
      if (this.searchValidation()) {
        this.overlay = true;
        try {
          let result = await api.getMyAllocation(this.list.list_name);

          if (result[0].child.length > 0) {
            this.myAllocation = result[0].child;

            this.myAllocation.sort((a, b) => {
              const lotA = a.lot_no.toUpperCase();
              const lotB = b.lot_no.toUpperCase();
              if (lotA < lotB) {
                return -1;
              }
              if (lotA > lotB) {
                return 1;
              }

              return 0;
            });

            this.tastingData = this.myAllocation;
            this.showTable = true;
          } else {
            this.noDataFoundAlert("No Data found...!");
          }
        } catch (error) {
          this.showErrorAlert(error);
        } finally {
          this.overlay = false;
          this.scrollDown();
        }
      }
    },

    // ## Dialog

    submit() {
      this.dialogue = true;
    },
    close() {
      this.dialogue = false;
    },
    clear() {
      this.season = null;
      this.seasons = [];
      this.lists = [];
      this.offers = [];
      this.vendor = null;
      this.offer = null;
      this.list = null;
      this.myAllocation = [];
      this.tastingData = [];
      this.showTable = false;
      this.$refs.frmFilter.resetValidation();
    },

    // async save() {
    //   if (this.tastingData.length > 0) {
    //     if (this.tableValidtion()) {
    //       try {
    //         this.overlay = true;
    //         this.dialogue = false;
    //         await api.saveData(this.tastingData).then(() => {
    //           this.showSuccessAlert("Data Saved");
    //           this.$refs.frmFilter.reset();
    //           this.initialize();
    //         });
    //         // console.log(this.tastingData);
    //       } catch (error) {
    //         console.log(error);
    //       } finally {
    //         this.overlay = false;
    //       }
    //     }
    //   } else {
    //     this.showWarningAlert("Check Atleast One Row");
    //   }
    // },
    async save(data) {
      try {
        this.overlay = true;
        this.dialogue = false;
        await api.saveData(data).then(() => {
          this.showSuccessAlert("Data Saved");
          this.$refs.frmFilter.reset();
          this.initialize();
        });
        // console.log(this.tastingData);
      } catch (error) {
        console.log(error);
      } finally {
        this.overlay = false;
      }
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 1000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    noDataFoundAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    edit(item) {
      this.editedData = [];
      this.editedData.push(item);
    },

    checkData(item, click = false) {
      if (click == true) {
        return false;
      } else if (item.item_name == null) {
        return false;
      } else {
        return true;
      }
    },
    frmvalidation() {
      return this.$refs.frmFilter.validate();
    },
  },
  computed: {
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
