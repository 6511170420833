import axios from "axios";

export default {
  async getMyAllocation(listname) {
    return await axios
      .get("private-allocation/get-by-list-name/" + listname)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getVendors() {
    let type = "PRIVATE";
    return await axios
      .get("vendors/vendor/by/type/" + type)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getSeason(vendor_id) {
    return await axios
      .get(`private-vendor-offer/vendor-offer-season-by-vendor-id/${vendor_id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getOffers(vendor_id, season_id) {
    return await axios
      .get(
        `private-vendor-offer/get-offers-by-vendor-and-season?season_id=${season_id}&vendor_id=${vendor_id}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getList(season_vendor_offer_id) {
    return await axios
      .get(`private-allocation/my-allocation-list/${season_vendor_offer_id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async saveData(data) {
    return await axios
      .post("private-allocation/Update_child", { child: data })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
